<template>
  <div>
    <div class="ant-card-body">
      <div class="contant-box">
        <p class="title">导出报表表头展示</p>
        <div class="tabShow">
          <table border="1">
            <tr>
              <th>课程名称</th>
              <th>章节名称</th>
              <th>课次名称</th>
              <th>周播放人数</th>
              <th>周播完人数</th>
              <th>周完播率</th>
              <th>20%跳出来</th>
              <th>50%跳出来</th>
              <th>90%跳出来</th>
              <th>播放总人数</th>
              <th>播完总人数</th>
              <th>总完播率</th>
            </tr>
          </table>
        </div>

        <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.taskId"
          :loading="tableLoading" :pagination="false">
          <span slot="vipStatus" slot-scope="text, record">
            <span>{{ record.vipStatus == 1 ? '仅注册' : record.vipStatus == 2 ? '会期内' : record.vipStatus == 3 ?
              '会期过期' : '--'
            }}</span>
          </span>
          <span slot="action" slot-scope="text, record">
            <a @click="isDownloadClick(record)">下载</a>
          </span>
        </a-table>
      </div>
      <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading" />
    </div>
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { GetClassPlayLists } from "@/request/api/reportFormManage";
import { domainName } from "@/config";
import axios from "axios";
export default {
  components: { MyPagination },
  created () {
    this.getTagList();
  },

  data () {
    return {
      spinning: false, // 全局loading
      tableLoading: false,
      columns: [
        {
          title: "报表名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "系统生成时间",
          dataIndex: "create_time",
          key: "create_time",
          scopedSlots: { customRender: "create_time" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "130px",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableList: [],

      pageNo: 1, // 当前页
      pageSize: 20, // 每页条数
      count: 0,// 列表的总条数
      options: [],

      // 展示表头
      columnsShow: [
        {
          title: "报表名称",
          dataIndex: "userName",
          key: "userName",
        },
        {
          title: "系统生成时间",
          dataIndex: "nickName",
          key: "nickName",
          scopedSlots: { customRender: "nickName" },
        },
        {
          title: "进度",
          dataIndex: "vipStatus",
          key: "vipStatus",
          scopedSlots: { customRender: "vipStatus" },
        },
      ],
      tableListShow: [],
    };
  },

  watch: {
    $route (to) {
      if (to.path === "/reportFormManage/contentIndex") {
        this.getTagList();
      }
    },
  },

  methods: {
    // 分页功能切换的回调
    showSizeChangeFn (current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getTagList();
    },

    // 列表数据
    getTagList () {
      this.tableLoading = true
      GetClassPlayLists({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code === 200) {
          this.tableLoading = false;
          this.tableList = res.data.data;
          this.count = res.data.count;
        } else {
          this.tableLoading = false;
        }
      });
    },

    // 下载
    isDownloadClick (record) {
      let token = localStorage.getItem("token");
      this.tableLoading = true
      let urlLink = domainName
      if (urlLink != "https://test.shenyiedu.com/") {
        urlLink = 'https://admin.shenyiedu.com/'
      }
      let url = `${urlLink}/adminv2/statistics/DownClassPlayLists`
      let obj = {
        task_id: record.taskId
      }
      axios.post(url, obj, {
        responseType: 'blob',
        headers: {
          'accesstoken': token,
          'authorization': token,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let blob = res.data;
        const link = document.createElement('a');
        blob = new Blob([blob], { type: 'application/x-excel' });
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        link.download = `${record.name}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.tableLoading = false
      });
    },

    // 回到顶部的函数
    targetFn () {
      return document.querySelector("#course");
    },
  }
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
  text-align: center;

  th {
    padding: 10px;
  }
}

.ant-card-body {
  padding: 0;
}

.item {
  margin-right: 20px;
  margin-bottom: 10px;
}

#course_category {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.head-box {
  display: flex;
  margin-bottom: 10px;
}

.avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.table-operator {
  display: flex;
  flex-wrap: wrap;
}
</style>
